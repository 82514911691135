import { AxiosResponse } from 'axios';
import * as queryString from 'query-string';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import momentTimezone from 'moment-timezone';
import trimStart from 'lodash/trimStart';
import { NEW_YORK_TIMEZONE } from '@usga/modules';
import {
  ApplicationAnonResponseModel,
  ApplicationProgressionEnum,
  ApplicationResponseModel,
  ChampionshipPlayerModel,
  LinkedProfilePlayerResponseModel,
  WorkflowTypeEnum,
} from '@usga/champadmin-api';
import { FourballPlayerNumber, IOutsideInfo } from './interfaces';
import { FlowType } from '../interfaces';

export function buildPagePath(pagePath: string, forceStep: string) {
  return `${pagePath}#${forceStep}`;
}

export function getForceStep(): string {
  return trimStart(window.location.hash, '#');
}

export function hasForceStep() {
  return Boolean(getForceStep());
}

function formatDate(inputDate: Date) {
  const edtFormatted = momentTimezone
    .utc(inputDate)
    .tz(NEW_YORK_TIMEZONE)
    .format('MM/DD/YYYY, hh:mm:ss A');
  return `${edtFormatted} EDT`;
}

export function formatBefore(startDate: string) {
  const dateText = formatDate(new Date(startDate));
  return `Applications will be available on ${dateText}.`;
}

export function formatAfter(endDate: string) {
  const dateText = formatDate(new Date(endDate));
  return `Applications closed on ${dateText}. Late applications will not be accepted.`;
}

export function decorateRequest<T extends {}>(obs: Observable<AxiosResponse<T>>): Observable<T> {
  return obs.pipe(
    timeout(10000),
    map((res) => res.data ?? {}),
    catchError(() => {
      return of({} as T);
    })
  );
}

export function resetApplication(
  applicationModel: ApplicationResponseModel | {}
): ApplicationResponseModel | {} {
  if ('progression' in applicationModel) {
    if (applicationModel.progression !== ApplicationProgressionEnum.DRAFT) {
      return {};
    } else {
      return {
        ...applicationModel,
        agreements: void 0, // unset all agreements
        qualifyingSites: [], // unset predefined sites
        exemption: [], // unset predefined exemptions
      };
    }
  }

  return applicationModel;
}

export function getOutside(championship: ChampionshipPlayerModel): IOutsideInfo | null {
  const { applicationStartDate, applicationEndDate } = championship;
  const now = new Date();
  const appOpenDate = applicationStartDate ? new Date(applicationStartDate) : null;
  const appCloseDate = applicationEndDate ? new Date(applicationEndDate) : null;
  if (appOpenDate && now.getTime() <= appOpenDate.getTime()) {
    return {
      outsideType: 'before',
      champ: championship,
    };
  } else if (appCloseDate && now.getTime() >= appCloseDate.getTime()) {
    return {
      outsideType: 'after',
      champ: championship,
    };
  }
  return null;
}

export function getAppIDFromURL(): string | null {
  return String(queryString.parse(window.location.search).appId);
}

export function getFourballPlayerStatus(
  workflowType: WorkflowTypeEnum
): FourballPlayerNumber | null {
  const appId = queryString.parse(window.location.search).appId;
  return workflowType !== WorkflowTypeEnum.FOURBALL ? null : appId ? 'second' : 'first';
}

export function isLateAppliance(championship: ChampionshipPlayerModel) {
  return (
    !!championship.lateApplyDeadlineDate &&
    new Date().getTime() < new Date(championship.lateApplyDeadlineDate).getTime() &&
    !!championship.applicationEndDate &&
    new Date().getTime() > new Date(championship.applicationEndDate).getTime()
  );
}

export function isLateApplianceAllowed(
  championship: ChampionshipPlayerModel,
  player: LinkedProfilePlayerResponseModel
): boolean {
  return (
    !!championship.lateApplyDeadlineDate &&
    new Date().getTime() < new Date(championship.lateApplyDeadlineDate).getTime() &&
    !!championship.eligibleLateApplyPlayers?.some(({ id }) => id === player.id)
  );
}

export function mapFlowType(flowType: FlowType, workflowType: WorkflowTypeEnum): WorkflowTypeEnum {
  switch (flowType) {
    case FlowType.Adaptive: {
      return WorkflowTypeEnum.ADAPTIVE;
    }
    case FlowType.Amateur: {
      return workflowType === WorkflowTypeEnum.USAMATEUR
        ? WorkflowTypeEnum.USAMATEUR
        : WorkflowTypeEnum.AMATEUR;
    }
    case FlowType.FourballFirst: {
      return WorkflowTypeEnum.FOURBALL;
    }
    case FlowType.FourballSecond: {
      return WorkflowTypeEnum.FOURBALL;
    }
    case FlowType.FourballSubstitution: {
      return WorkflowTypeEnum.FOURBALL;
    }
    case FlowType.Open: {
      return WorkflowTypeEnum.OPEN;
    }
    case FlowType.Usopen: {
      return WorkflowTypeEnum.USOPEN;
    }
  }
}

export function isSubstitution(application: ApplicationAnonResponseModel) {
  return (
    application.progression === ApplicationProgressionEnum.NEEDPARTNER &&
    [...application.exemption, ...application.qualifyingSites, ...application.waitList].length > 0
  );
}
